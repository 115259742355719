import React from 'react'
import { useParams, Link } from 'react-router-dom'
import ArrowUpRight from '../assets/svgs/icons/arrow-up-right.svg'
import FeaturesCard from '../components/FeaturesCard';
import PlaningImg from '../assets/images/consultation/planing.jpeg'
import OperationalImg from '../assets/images/consultation/operational.jpeg'
import ConsultingImg from '../assets/images/consultation/consulting.png'
import TestingIcon from '../assets/svgs/icons/approach/testing+icon.svg'
import Chip from '../components/Chip'
import LightBulbIcon from '../assets/svgs/icons/light+bulb+icon.svg'
import TelehealthIcon from '../assets/svgs/icons/telehealth+icon.svg'
import BenefitCard from '../components/BenefitCard';
import PageTitle from '../components/PageTitle';
import JumbotronVid from '../assets/videos/jumbotron+vid.mp4'
import ConsultationImg from '../assets/images/Consultation.webp'
import WebDevImg from '../assets/images/development.webp'
import AppDevImg from '../assets/images/app+development.webp'
import SystemIntImg from '../assets/images/system+integration.webp'
import LegacySysImg from '../assets/images/legacy+system.webp'
import SupportImg from '../assets/images/support.webp'
import ClipboardIcon from '../assets/svgs/icons/process/clipboard+icon.svg'
import PlanIcon from '../assets/svgs/icons/process/plan+icon.svg'
import SupportIcon from '../assets/svgs/icons/process/support+icon.svg'
import TrendIcon from '../assets/svgs/icons/process/trend+icon.svg'
import ProcessInfo from '../components/ProcessInfo';

import Support from '../assets/images/features/247+support.jpeg'
import CodeRefImg from '../assets/images/features/code+refactoring.jpeg'
import CompatibilityImg from '../assets/images/features/compatibility.jpeg'
import DataImg from '../assets/images/features/data.jpeg'
import FeaturesImg from '../assets/images/features/features.jpeg'
import IntegrationImg from '../assets/images/features/integration.jpeg'
import InterfaceImg from '../assets/images/features/interface.jpeg'
import MontoringImg from '../assets/images/features/monitoring.jpeg'
import SecureImg from '../assets/images/features/secure.jpeg'
import SecureTransferImg from '../assets/images/features/secure+transfer.jpeg'
import SecurityEnhancementImg from '../assets/images/features/security+enhancement.jpeg'
import SolutionImg from '../assets/images/features/solution.jpeg'
import UpdatesImg from '../assets/images/features/updates.jpeg'
import UXDesignImg from '../assets/images/features/ux+design.jpeg'
import WebDesignImg from '../assets/images/features/web+design.jpeg'

import CheckIcon from '../assets/svgs/icons/offerings/check+icon.svg'
import LawIcon from '../assets/svgs/icons/offerings/law+icon.svg'
import ChatIcon from '../assets/svgs/icons/offerings/chat+icon.svg'
import HealthcareIcon from '../assets/svgs/icons/offerings/health+icon.svg'
import WellnessIcon from '../assets/svgs/icons/offerings/wellness+icon.svg'
import DataIcon from '../assets/svgs/icons/offerings/data+icon.svg'

import EhrIcon from '../assets/svgs/icons/offerings/ehr+icon.svg'
import PmsIcon from '../assets/svgs/icons/offerings/pms+icon.svg'
import TelemedicIcon from '../assets/svgs/icons/offerings/telemedicine+icon.svg'
import HieIcon from '../assets/svgs/icons/offerings/hie+icon.svg'
import ReportIcon from '../assets/svgs/icons/offerings/report+icon.svg'
import InvoiceIcon from '../assets/svgs/icons/offerings/invoice+icon.svg'


import PhmIcon from '../assets/svgs/icons/offerings/phm+icon.svg'
import PcaIcon from '../assets/svgs/icons/offerings/pca+icon.svg'
// import TelehealthIcon from '../assets/svgs/icons/offerings/telehealth+icon.svg'
import MmaIcon from '../assets/svgs/icons/offerings/mma+icon.svg'
import FitnessIcon from '../assets/svgs/icons/offerings/fitness+icon.svg'
import CdmIcon from '../assets/svgs/icons/offerings/cdm+icon.svg'

const Servicedetail = () => {
    const params = useParams();

    React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page


    const pages = [
        {"test" : "don't delete"}, // this is meant to serve as a dummy index 0
        {
            id : 1,
            heroTitle : 'Consultation',
            heroDesc : "Our consultation services offer expert advice and strategies to help optimize your healthcare operations. SeamHealth's consultants bring a wealth of experience and knowledge to guide you through challenges and opportunities in the healthcare sector.",
            heroImage : ConsultationImg,
            features : [
                {
                    title : "Operational Assessment",
                    desc : "Comprehensive reviews of current practices to identify inefficiencies and areas for improvement.",
                    image : OperationalImg
                },
                {
                    title : "Strategic Planning",
                    desc : "Developing actionable plans to achieve your organizational goals, whether it's expanding services, improving patient care, or adopting new technologies.",
                    image : PlaningImg
                },
                {
                    title : "Technology Consulting",
                    desc : "Advising on the best tech solutions to meet your needs, from software and hardware selection to implementation strategies.",
                    image : ConsultingImg
                }
            ],
            offering : [
                {
                    title : "Operational Efficiency Assessments",
                    image : CheckIcon
                },
                {
                    title : "Regulatory Compliance Consulting",
                    image : LawIcon
                },
                {
                    title : "IT Infrastructure Consulting",
                    image : ChatIcon
                },
                {
                    title : "Healthcare Workflow Optimization",
                    image : HealthcareIcon
                },
                {
                    title : "Patient Experience Enhancement",
                    image : WellnessIcon
                },
                {
                    title : "Data Security and Privacy Consulting",
                    image : DataIcon
                }
            ],
            benefits : [
                {
                    title : 'Expert Insights',
                    desc : 'Leverage our deep industry knowledge to make informed decisions that drive success.'
                },
                {
                    title : 'Custom Solutions',
                    desc : 'Tailored advice and strategies specific to your unique situation and goals.'
                },
                {
                    title : 'Improved Outcomes',
                    desc : 'Implementing best practices and innovative solutions that lead to better patient care and operational efficiency.'
                }
            ],
            jumbotron : [
                {
                    title : 'Book a Consultation',
                    desc : 'Unlock your practice’s potential with our expert guidance. Schedule a consultation today and take the first step towards achieving your goals.'
                }
            ]
        },
        {
            id : 2,
            heroTitle : 'Custom Software Development',
            heroDesc : "At SeamHealth, we specialize in creating custom software solutions tailored to the unique needs of healthcare institutions and practices. Our approach ensures that every solution is designed to meet your specific operational goals, improve patient care, and enhance workflow efficiency.",
            heroImage : WebDevImg,
            features : [
                {
                    title : "Bespoke Design",
                    desc : "Custom-built to fit your exact specifications, ensuring the solution is perfectly aligned with your requirements.",
                    image : WebDesignImg
                },
                {
                    title : "Scalable Solutions",
                    desc : "Designed to grow with your practice, adapting to new challenges and opportunities.",
                    image : SolutionImg
                },
                {
                    title : "User-Centric Interface",
                    desc : "Intuitive designs that prioritize user experience, making it easier for staff and patients to use.",
                    image : InterfaceImg
                }
            ],
            offering : [
                {
                    title : "Electronic Health Records (EHR) Systems",
                    image : EhrIcon
                },
                {
                    title : "Patient Management Systems",
                    image : PmsIcon
                },
                {
                    title : "Billing and Invoicing Software",
                    image : InvoiceIcon
                },
                {
                    title : "Telemedicine Platforms",
                    image : TelemedicIcon
                },
                {
                    title : "Health Information Exchange (HIE)",
                    image : HieIcon
                },
                {
                    title : "Custom Reporting Tools",
                    image : ReportIcon
                }
            ],
            benefits : [
                {
                    title : 'Enhanced Efficiency',
                    desc : 'Streamlined processes and workflows that reduce administrative burden and improve productivity.'
                },
                {
                    title : 'Improved Patient Care',
                    desc : 'Advanced tools that support better patient management, from appointment scheduling to electronic health records (EHR).'
                },
                {
                    title : 'Competitive Advantage',
                    desc : 'Advanced tools that support better patient management, from appointment scheduling to electronic health records (EHR).'
                }
            ],
            jumbotron : [
                {
                    title : 'Get Started',
                    desc : 'Transform your healthcare practice with custom software solutions. Contact us today for a consultation.'
                }
            ]
        },
        {
            id : 3,
            heroTitle : 'Mobile App Development',
            heroDesc : "SeamHealth develops user-friendly mobile applications that empower both patients and healthcare providers. Our apps are designed to facilitate access to healthcare services anytime, anywhere, ensuring convenience and improved health outcomes.",
            heroImage : AppDevImg,
            features : [
                {
                    title : "Cross-Platform Compatibility",
                    desc : "Available on both iOS and Android, ensuring maximum reach and usability.",
                    image : CompatibilityImg
                },
                {
                    title : "Secure and Compliant",
                    desc : "Adhering to healthcare regulations and standards, including HIPAA, to protect patient data.",
                    image : SecureImg
                },
                {
                    title : "Feature-Rich",
                    desc : "Including functionalities such as appointment scheduling, telehealth, medication reminders, and patient education.",
                    image : FeaturesImg
                }
            ],
            offering : [
                {
                    title : "Patient Health Management Apps",
                    image : PhmIcon
                },
                {
                    title : "Provider Communication Apps",
                    image : PcaIcon
                },
                {
                    title : "Telehealth Apps",
                    image : TelehealthIcon
                },
                {
                    title : "Medication Management Apps",
                    image : MmaIcon
                },
                {
                    title : "Fitness and Wellness Apps",
                    image : FitnessIcon
                },
                {
                    title : "Chronic Disease Management Apps",
                    image : CdmIcon
                }
            ],
            benefits : [
                {
                    title : 'Increased Accessibility',
                    desc : 'Patients can access services from their mobile devices, leading to better engagement and adherence to treatment plans.'
                },
                {
                    title : 'Better Engagement',
                    desc : 'Improved communication between patients and providers through in-app messaging, video calls, and notifications.'
                },
                {
                    title : 'Operational Efficiency',
                    desc : 'Automate routine tasks such as appointment scheduling, reminders, and patient follow-ups, freeing up staff to focus on care.'
                }
            ],
            jumbotron : [
                {
                    title : 'Start Your Project',
                    desc : 'Revolutionize patient care with our mobile app solutions. Get in touch to discuss your project and see how we can help.'
                }
            ]
        },
        {
            id : 4,
            heroTitle : 'System Integration',
            heroDesc : "SeamHealth’s system integration services ensure seamless data transfer and improved interoperability between different healthcare systems. We bridge gaps to create a unified, efficient, and reliable ecosystem that enhances communication and collaboration across your organization.",
            heroImage : SystemIntImg,
            features : [
                {
                    title : "Data Interoperability",
                    desc : "Ensuring different systems can communicate effectively, sharing data accurately and efficiently.",
                    image : DataImg
                },
                {
                    title : "Custom Integrations",
                    desc : "Tailored solutions to meet specific integration needs, whether it's connecting EHR systems, lab systems, or other healthcare applications.",
                    image : IntegrationImg
                },
                {
                    title : "Secure Transfers",
                    desc : "Maintaining data security and compliance with healthcare regulations throughout the integration process.",
                    image : SecureTransferImg
                }
            ],
            offering : [
                {
                    title : "EHR Integration",
                    image : CheckIcon
                },
                {
                    title : "Laboratory Information System (LIS) Integration",
                    image : LawIcon
                },
                {
                    title : "Radiology Information System (RIS) Integration",
                    image : ChatIcon
                },
                {
                    title : "Pharmacy Management System Integration",
                    image : HealthcareIcon
                },
                {
                    title : "Billing System Integration",
                    image : WellnessIcon
                },
                {
                    title : "Health Information Exchange (HIE) Integration",
                    image : DataIcon
                }
            ],
            benefits : [
                {
                    title : 'Streamlined Operations',
                    desc : 'Reduced manual data entry and errors, leading to more efficient workflows.'
                },
                {
                    title : 'Enhanced Collaboration',
                    desc : 'Better information flow between departments and care providers, leading to improved patient outcomes.'
                },
                {
                    title : 'Improved Patient Care',
                    desc : 'Access to comprehensive patient data in one place, enabling more informed decision-making.'
                }
            ],
            jumbotron : [
                {
                    title : 'Integrate Now',
                    desc : 'Achieve seamless operations with our integration services. Contact us to learn more and start your integration project.'
                }
            ]
        },
        {
            id : 5,
            heroTitle : 'Legacy System Modernization',
            heroDesc : "Upgrade your outdated software systems with SeamHealth’s legacy system modernization services. We enhance functionality, security, and user experience, ensuring your systems meet current standards and future needs.",
            heroImage : LegacySysImg,
            features : [
                {
                    title : "Code Refactoring",
                    desc : " Updating old codebases for better performance and maintainability.",
                    image : CodeRefImg
                },
                {
                    title : "UI/UX Redesign",
                    desc : "Creating modern, intuitive user interfaces that improve user satisfaction.",
                    image : UXDesignImg
                },
                {
                    title : "Security Enhancements",
                    desc : "Implementing the latest security measures to protect against modern threats and vulnerabilities.",
                    image : SecurityEnhancementImg
                }
            ],
            offering : [
                {
                    title : "Code Refactoring and Optimization",
                    image : CheckIcon
                },
                {
                    title : "User Interface (UI) and User Experience (UX) Redesign",
                    image : LawIcon
                },
                {
                    title : "Database Migration and Optimization",
                    image : ChatIcon
                },
                {
                    title : "Security Enhancements and Audits",
                    image : HealthcareIcon
                },
                {
                    title : "Functionality Upgrades",
                    image : WellnessIcon
                },
                {
                    title : "Performance Tuning",
                    image : DataIcon
                }
            ],
            benefits : [
                {
                    title : 'Improved Performance',
                    desc : 'Faster and more efficient systems that enhance productivity and user satisfaction.'
                },
                {
                    title : 'Enhanced Security',
                    desc : 'Protection against modern threats, ensuring data integrity and compliance with regulations.'
                },
                {
                    title : 'Better User Experience',
                    desc : 'Intuitive and easy-to-use interfaces that reduce training time and improve adoption.'
                }
            ],
            jumbotron : [
                {
                    title : 'Modernize Today',
                    desc : 'Bring your systems up to date with SeamHealth’s modernization services. Contact us to discuss your modernization needs and see how we can help.'
                }
            ]
        },
        {
            id : 6,
            heroTitle : 'Support and Maintenance',
            heroDesc : "SeamHealth provides comprehensive support and maintenance services to ensure your healthcare software runs smoothly and remains up-to-date. Our team is dedicated to addressing your technical needs promptly and effectively, minimizing downtime and ensuring continuous operation.",
            heroImage : SupportImg,
            features : [
                {
                    title : "24/7 Support",
                    desc : "Around-the-clock assistance for any issues, ensuring your systems are always operational.",
                    image : Support
                },
                {
                    title : "Regular Updates",
                    desc : "Keeping your software current with the latest features, security patches, and performance improvements.",
                    image : UpdatesImg
                },
                {
                    title : "Performance Monitoring",
                    desc : "Proactive monitoring to detect and address issues before they impact your operations.",
                    image : MontoringImg
                }
            ],
            offering : [
                {
                    title : "Technical Support",
                    image : CheckIcon
                },
                {
                    title : "Software Maintenance and Updates",
                    image : LawIcon
                },
                {
                    title : "Performance Monitoring",
                    image : ChatIcon
                },
                {
                    title : "Security Audits and Patching",
                    image : HealthcareIcon
                },
                {
                    title : "Backup and Disaster Recovery",
                    image : WellnessIcon
                },
                {
                    title : "User Training and Documentation",
                    image : DataIcon
                }
            ],
            benefits : [
                {
                    title : 'Reduced Downtime',
                    desc : 'Minimized interruptions to your operations, ensuring continuous patient care.'
                },
                {
                    title : 'Enhanced Security',
                    desc : 'Continuous protection against vulnerabilities and threats.'
                },
                {
                    title : 'Peace of Mind',
                    desc : 'Knowing expert help is always available to address any technical issues.'
                }
            ],
            jumbotron : [
                {
                    title : 'Get Support',
                    desc : 'Ensure your systems run flawlessly with SeamHealth’s support and maintenance services. Contact us for reliable support and maintenance solutions.'
                }
            ]
        },
    ]

    


    // const page = (id) =>{
        
    // }

    // console.log(consultation[1].heroTitle)

    // const services = [
        
    // ]
  return (
    <>
        {/* Servicedetail page: {params.service_id} */}
        {/* {services.id} */}
        {/* Set Page title */}
      <PageTitle title={"" +pages[params.service_id].heroTitle+" | SeamHealth Website "} /> 

                {/* Values Section */}
                <section className="single-service-section pt-36" id="values-section">
            <div className='grid grid-cols-1 md:grid-cols-2 p-4 md:p-8 gap-4'>
                <div>
                    <h4 className="heading-four">{pages[params.service_id].heroTitle}</h4>
                </div>

                <div>
                    <p className=''>
                    {pages[params.service_id].heroDesc}
                    </p>
                </div>
            </div>

            <div className='big-image' style={{backgroundImage : 'url('+pages[params.service_id].heroImage+')',}}></div>
            {/* <img src={ConsultationImg} alt="" style={{width : '100vw'}}/> */}
        </section>


        <section className='features-section py-8 md:py-32'>
            <div className="p-4 md:p-8">
                <h4 className="heading-four text-center text-gray mb-8">Features</h4>
                <div className="grid md:grid-cols-3 gap-6">
                    {/* {for(let i = 0; i < pages[params.service_id].features.length; i++  ) {   */}
                        <FeaturesCard 
                        image={pages[params.service_id].features[0].image}
                        title={pages[params.service_id].features[0].title} 
                        desc={pages[params.service_id].features[0].desc} />

                    <FeaturesCard 
                        image={pages[params.service_id].features[1].image}
                        title={pages[params.service_id].features[1].title} 
                        desc={pages[params.service_id].features[1].desc} />

                    <FeaturesCard 
                        image={pages[params.service_id].features[2].image}
                        title={pages[params.service_id].features[2].title} 
                        desc={pages[params.service_id].features[2].desc} />
{/* } */}
                    
                </div>
            </div>
        </section>


        {/* solution */}
        <section className='solution-section py-28 p-8 '>
              <div className="s-inner flex flex-col justify-center items-center rounded-2xl">
                {/* <div> */}
                  
                  <img src={LightBulbIcon} alt="" className='mb-8' />
                    <h4 className="heading-four mb-4 text-center">
                        Some of Our Key Offerings
                    </h4>
                {/* </div> */}

                <div className='flex gap-x-8 mt-8'>
                  <Chip title={pages[params.service_id].offering[0].title} icon={pages[params.service_id].offering[0].image} />
                  <Chip title={pages[params.service_id].offering[1].title} icon={pages[params.service_id].offering[1].image} />
                  <Chip title={pages[params.service_id].offering[2].title} icon={pages[params.service_id].offering[2].image} />
                </div>

                <div className='flex gap-x-8 mt-4 '>
                <Chip title={pages[params.service_id].offering[3].title} icon={pages[params.service_id].offering[3].image} />
                  <Chip title={pages[params.service_id].offering[4].title} icon={pages[params.service_id].offering[4].image} />
                  <Chip title={pages[params.service_id].offering[5].title} icon={pages[params.service_id].offering[5].image} />
                </div>
                <div className="crescent"></div>
              </div>
        </section>


         {/* benefits */}
         <section className='benefits-section py-28 p-4 md:p-8 '>
            <h4 className="heading-four mb-4 text-center">
                Benefits
            </h4>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <BenefitCard 
                    position={"01"}
                    title={pages[params.service_id].benefits[0].title}
                    desc={pages[params.service_id].benefits[0].desc}
                />

                <BenefitCard 
                    position={"02"}
                    title={pages[params.service_id].benefits[1].title}
                    desc={pages[params.service_id].benefits[1].desc}
                />

                <BenefitCard 
                    position={"03"}
                    title={pages[params.service_id].benefits[2].title}
                    desc={pages[params.service_id].benefits[2].desc}
                />
            </div>
         </section>

         <div className="md:p-8">
             <section className='jumbotron-alt py-28'>
                {/* <div className="overlay"></div> */}
                <video id="" autoPlay loop muted width={'2200px'} draggable="false" style={{zIndex : -5}}>
                    <source src={JumbotronVid} type="video/mp4" />
                </video>
                <div className='info flex flex-col justify-center items-center gap-6 text-white'>
                    <h4 className="heading-four text-center">
                        {pages[params.service_id].jumbotron[0].title}
                    </h4>
                    <p className='text-center text-gray-300 '>{pages[params.service_id].jumbotron[0].desc}</p>
                    <Link to={"/get-in-touch"} className='btn btn-primary w-max'>Contact us <img src={ArrowUpRight} alt="" /></Link>
                </div>
                     </section>
         </div>

        <section className='process-section py-8 md:py-28 p-8'>
            
            <div className="grid grid-cols-1 gap-12 md:p-32">

                <ProcessInfo 
                    title={"Initial Assessment"}
                    desc={"Understanding your challenges, goals, and current operations through detailed consultations."}
                    image={ClipboardIcon} />

                <ProcessInfo 
                    title={"Customized Plan"}
                    desc={"Developing a tailored strategy that addresses your specific needs and objectives."}
                    image={PlanIcon} />

                <ProcessInfo 
                    title={"Implementation Support"}
                    desc={"Assisting with the execution of the plan, providing guidance and support at every step."}
                    image={SupportIcon} />

                <ProcessInfo 
                    title={"Continuous Improvement"}
                    desc={"Ongoing reviews and adjustments to ensure sustained success and adaptability to new challenges."}
                    image={TrendIcon} />
                
            </div>

        </section>
    </>
  )
}

export default Servicedetail