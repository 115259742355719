import {React, useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import { Link } from 'react-router-dom'
import ArrowRight from '../assets/svgs/icons/arrow-right.svg'
import Tag from './Tag'

const ProjectCard = ({ image, logo, desc, link, title, landscape = false, invert = false, tag = true }) => {
    
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

  return (
    <>
        <a href={link}>
        <div data-aos="fade-up" className={landscape ? 'project-card items-center' : 'project-card flex-col items-center'} >
                <div>
                    <img src={image} alt="" />
                </div>
                <div className='gap-4 w-full'>
                    {tag && <div className='flex gap-x-2 mb-3'>
                        <Tag title={"Mobile App"} />
                        <Tag title={"Web"} />
                    </div>}
                    <h6 className="heading-six">{title}</h6>
                    <img src={logo} className='mb-2' style={{height : 44}} alt="" draggable={false} />
                    <p className='text-gray'>{desc}</p>
                    {link && <div className="mt-4 w-max">
                        {/* <a className='btn btn-link' href={link}>View Details</a> */}
                        <Link to={link} className='btn btn-link text-primary500' style={{padding : 0}}>View Details<img src={ArrowRight} alt="" /> </Link>
                    </div>}
                </div>
            </div>
        </a>
    </>
  )
}

export default ProjectCard