import React from 'react'
import UserIcon from '../assets/svgs/icons/user+icon.svg'
import EmailIcon from '../assets/svgs/icons/mail+icon.svg'
import PhoneIcon from '../assets/svgs/icons/phone+icon.svg'
import Tag from '../components/Tag'
import CircleIcon from '../assets/svgs/icons/circle+icon.svg'
import CircleIconAlt from '../assets/svgs/icons/circle+icon+alt.svg'
import PageTitle from '../components/PageTitle'

const Getintouch = () => {
    React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page
  return (
    <>
        {/* Set Page title */}
        <PageTitle title="Get in Touch | SeamHealth Website " /> 
        <div id='address-section' className="contact-section pt-28 md:pt-52 p-4 md:p-8 flex justify-between flex-col gap-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div data-aos="fade-up" className='text-white '>
                <Tag title={"Contact us"} alt={true} />
                <h4 data-aos="fade-up" className="heading-four">We'd love to hear from you</h4>
              </div>
              <p data-aos="fade-up" className='text-white'>
                Thank you for visiting our website. If you have any questions or comments about our services, feel free to reach out. We aim to respond to all inquiries within 24 hours and look forward to working with you.
              </p>
          </div>

          <div className="grid md:grid-cols-2 gap-12 md:gap-8 items-end">
              <div data-aos="fade-up" className='text-white grid grid-cols-1 gap-6'>
                <div>
                    <p className='text-primary50'>Email</p>
                    <p>info@seamhealthgroup.com</p>
                </div>

                <div>
                    <p className='text-primary50'>Phone</p>
                    <p >+2349151104924</p>
                </div>

                <div>
                    <p className='text-primary50'>Office address</p>
                    <p>International Research Center of Excellence Towers. 6, Emeritus Umar Shehu Ave. (5th Floor, Tower A), Institutions and Research District, Abuja, Nigeria.</p>
                </div>
              </div>
              
              <a href="#message-section" className='py-4 md:py-0'>
                <div className='message-cta flex gap-3 items-center justify-center md:justify-end'>
                    <img src={CircleIcon} alt="" />
                    <p className='text'>Write us a message</p>
                </div>
            </a>
          </div>
        </div>
            
        <div id='message-section' className="message-section pt-28 md:pt-52 p-4 md:p-8 grid grid-cols-1 md:grid-cols-2">
            <div className="flex flex-col justify-between items-start">
                <div data-aos="fade-up" className=''>
                    <h4 className="heading-four mb-4">Write us a message</h4>
                    <p className='text-gray'>Another option is to contact us through the provided email address and phone number above</p>
                </div>

                <a href="#address-section" className=''>
                <div className='address-cta md:flex gap-3 items-center justify-end hidden '>
                    <img src={CircleIconAlt} alt="" />
                    <p className='text'>Our Address</p>
                </div>
            </a>
            </div>

            <div data-aos="fade-up" className='form w-max mt-8 md:mt-0' >
                <form method='POST' action='#'>
                <div className="f-inner">
                    <div className="form-group w-full">
                        Name
                        <input type="text" className="form-control w-full" placeholder='Name' />
                        <img src={UserIcon} alt="" />
                    </div>

                    <div className="form-group w-full">
                        Email
                        <input type="email" className="form-control w-full" placeholder='Email address' />
                        <img src={EmailIcon} alt="" />
                    </div>

                    <div className="form-group w-full">
                        Phone number
                        <input type="tel" className="form-control w-full" placeholder='Phone number' />
                        <img src={PhoneIcon} alt="" />
                    </div>

                    <div className=" w-full">
                        Message
                        <textarea name="" id="" cols="4" rows={10} className='form-control w-full' placeholder='Write your message'></textarea>
                    </div>

                    <div className='w-full'>
                        <button type='submit' className='btn btn-primary w-full md:w-max'>Send message</button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </>
  )
}

export default Getintouch