import React from 'react'

const Chip = ({ title, icon }) => {
  return (
    <>
        <div className='chip p-3 flex items-center gap-x-3 bg-white rounded-2xl' >
            <img src={icon} alt="" />
            <p className="text-gray">{title}</p>
        </div>
    </>
  )
}

export default Chip