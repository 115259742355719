import React, { useEffect } from 'react'
import {useParams, Link} from 'react-router-dom'
import Chip from '../components/Chip'
import LightBulbIcon from '../assets/svgs/icons/light+bulb+icon.svg'
import HealthcareIcon from '../assets/svgs/icons/offerings/health+icon.svg'
import PageTitle from '../components/PageTitle'
import ProjectCard from '../components/ProjectCard'
import LogoAlt from '../assets/svgs/logo/seamhealth+logo+alt.svg'
import ElsrtMockup from '../assets/images/elsrt+mockup.png'
import ElsrtLogo from '../assets/svgs/logo/elsrt+logo.svg'
import ClientshoImg01 from '../assets/images/projects/clientshot+mockups/clientshot+img01.png'
import ClientshoImg02 from '../assets/images/projects/clientshot+mockups/clientshot+img02.png'
import ClientshotMockup from '../assets/images/clientshot+mockup.png'
import ClientshotLogo from '../assets/svgs/logo/Clientshot_logo_white.svg'
import ClientshotLogo2 from '../assets/images/projects/clientshot+mockup2.png'
import Tag from '../components/Tag'
import ProjectStacks from '../assets/images/projects/project+stacks.webp'
import CircleIcon from '../assets/svgs/icons/circle+icon.svg'
import SmileTrainMockup from '../assets/images/projects/smile+train+mockup.png'
import SmileTrainLogo from '../assets/images/projects/smiletrain-logo.svg'
import BridgeLogoAlt from '../assets/images/projects/bridge+logo+alt.svg'
import BridgeMockup from '../assets/images/projects/bridge+mockup.png'
import Bridge01 from '../assets/images/projects/bridge+mockup/bridge01.png'
import Bridge02 from '../assets/images/projects/bridge+mockup/bridge02.png'
import BridgeImg01 from '../assets/images/projects/bridge+mockup/bridgeimg01.png'
import BridgeImg02 from '../assets/images/projects/bridge+mockup/bridgeimg02.png'
import BridgeHero from '../assets/images/projects/bridge+mockup/bridge+hero.png'
import BillGatesLogo from '../assets/images/projects/bridge+mockup/bill+gates+logo.svg'


import EkitiMockup from '../assets/images/projects/ekiti+health+mockup.png'
import EkitiLogo from '../assets/images/projects/ekiti+mockup/ekiti+logo.svg'
import EkitiHero from '../assets/images/projects/ekiti+mockup/ekiti+hero.png'
import Ekiti01 from '../assets/images/projects/ekiti+mockup/ekiti01.png'
import Ekiti02 from '../assets/images/projects/ekiti+mockup/ekiti02.png'
import EkitiImg01 from '../assets/images/projects/ekiti+mockup/ekiti+img01.png'
import EkitiImg02 from '../assets/images/projects/ekiti+mockup/ekiti+img02.png'

import KncvMockup from '../assets/images/projects/kncv+mockup.png'
import KncvLogo from '../assets/images/projects/kncv+logo.svg'
import KncvHero from '../assets/images/projects/kncv+mockup/kncv+hero.png'
import Kncv01 from '../assets/images/projects/kncv+mockup/kncv01.png'
import Kncv02 from '../assets/images/projects/kncv+mockup/kncv02.png'
import KncvImg01 from '../assets/images/projects/kncv+mockup/kncv+img01.png'
import KncvImg02 from '../assets/images/projects/kncv+mockup/kncv+img02.png'


import ArrowUpRight from '../assets/svgs/icons/arrow-up-right.svg'
import clientshot01 from '../assets/images/projects/clientshot+mockups/clientshot001.png'
import clientshot02 from '../assets/images/projects/clientshot+mockups/clientshot002.png'
import clientshot03 from '../assets/images/projects/clientshot+mockups/clientshot003.png'
import clientshot04 from '../assets/images/projects/clientshot+mockups/clientshot004.png'
import clientshot05 from '../assets/images/projects/clientshot+mockups/clientshot005.png'
import clientshot06 from '../assets/images/projects/clientshot+mockups/clientshot006.png'
import smiletrainthumbnail from '../assets/images/projects/smiletrain+mockup/smiletrain+thumbnail.png'
import smiletrainHero from '../assets/images/projects/smiletrain+mockup/smiletrain+hero.png'
import ElsrtHero from '../assets/images/projects/elsrt+mockup/elsrt+hero.png'
import ihvnLogo from '../assets/images/clients/ihvn.svg'
import Elsrt01 from '../assets/images/projects/elsrt+mockup/elsrt01.png'
import Elsrt02 from '../assets/images/projects/elsrt+mockup/elsrt02.png'
import Elsrt03 from '../assets/images/projects/elsrt+mockup/elsrt03.png'
import Elsrt04 from '../assets/images/projects/elsrt+mockup/elsrt04.png'
import Elsrt05 from '../assets/images/projects/elsrt+mockup/elsrt05.png'
import Elsrt06 from '../assets/images/projects/elsrt+mockup/elsrt06.png'


import smile01 from '../assets/images/projects/smiletrain+mockup/smile01.png'
import smile02 from '../assets/images/projects/smiletrain+mockup/smile02.png'
import smile03 from '../assets/images/projects/smiletrain+mockup/smile03.png'
import smile04 from '../assets/images/projects/smiletrain+mockup/smile04.png'
import smile05 from '../assets/images/projects/smiletrain+mockup/smile05.png'
import smile06 from '../assets/images/projects/smiletrain+mockup/smile06.png'

import ElsrtImg01 from '../assets/images/projects/elsrt+mockup/eslrt+img01.png'
import ElsrtImg02 from '../assets/images/projects/elsrt+mockup/eslrt+img02.png'

import SmileImg01 from '../assets/images/projects/smiletrain+mockup/smile+img01.png'
import SmileImg02 from '../assets/images/projects/smiletrain+mockup/smile+img02.png'

const Projectdetail2 = () => {
  const params = useParams();

  const pages = [
    {id : 0}, //Don't delete....
    {
      id : 1,
      title : 'Cleft and Surgery E-Registry Platform',
      logo : BridgeLogoAlt,
      mockup : BridgeMockup,
      heroImage : BridgeHero,
      summary : {
        desc: `SeamHealth Innovations was engaged by the Clinton Health Access Initiative (CHAI) and the Bill & Melinda Gates Foundation to develop a mobile app called BRIDGE (Breast Health Risk Assessment, Detection, and Guidance Engine). The BRIDGE app was designed to support community health workers (CHWs) in rural areas of Nigeria in conducting breast health assessments, improving early detection, and diagnosing breast cancer. 

The app provided CHWs with a structured assessment process, guiding them through a series of questions and decision trees to evaluate breast cancer risk. It also offered educational resources on breast health and clear referral pathways for patients with suspicious symptoms.`,
        problem: 'Low early detection and diagnosis of breast cancer in rural settings',
        industry: 'Service delivery (accessibility)',
        client: BillGatesLogo,
        url : '/'
      },
      features : [
        {
          title : 'Mobile Application',
          image : Bridge01
        },
        {
          title : 'Web Application',
          image : Bridge02
        },
      ],
      impact : {
        desc : `The BRIDGE app had the potential to significantly improve early detection and diagnosis of breast cancer in rural settings in Nigeria. By standardizing the assessment process and providing a clear referral pathway, the app helped CHWs identify and refer patients with suspicious symptoms more effectively. This resulted in earlier detection and diagnosis of breast cancer, leading to better treatment outcomes for patients.`,
        images : [BridgeImg01, BridgeImg02]
      }
    },
    {
      id : 2,
      title : 'Ekiti PVT/LIMS',
      logo : EkitiLogo,
      mockup : ElsrtMockup,
      heroImage : EkitiHero,
      summary : {
        desc: `The Ekiti State Ministry of Health and Human Services engaged SeamHealth Innovations to develop a single web application that consists of a Laboratory Information Management System (LIMS) and a Post-COVID-19 vaccination follow-up tracking system. The Laboratory Information Management System (LIMS) will improve the management and flow of laboratory samples and associated data, leading to more efficient lab workflows and procedures, and accurate controls of the process. 

The Post-COVID-19 vaccination follow-up tracking system will use USSD and WhatsApp Chatbot technology to track patient data and monitor vaccine effectiveness, identify adverse reactions, and increase overall vaccine coverage.`,
        problem: ' Inefficient management of laboratory samples and lack of proper tracking of post-COVID-19 vaccination',
        industry: 'Service delivery (accessibility)',
        client: ihvnLogo,
        url : 'https://dev.elsrt.com',
      },
      features : [
        {
          title : 'LIMS Application',
          image : Ekiti01
        },
        {
          title : 'PVT Application',
          image : Ekiti02
        },
      ],
      impact : {
        desc : `This project led to improved healthcare outcomes for the residents of Ekiti State, Nigeria by increasing the efficiency and effectiveness of laboratory sample management and post-COVID-19 vaccination tracking. The successful implementation of the solution enhanced healthcare access for the people of Ekiti State.`,
        images : [EkitiImg01, EkitiImg02]
      }
    },
    {
      id : 3,
      title : 'KNCV SMS/USSD',
      logo : KncvLogo,
      mockup : SmileTrainMockup,
      heroImage : KncvHero,
      summary : {
        desc: `The KNCV Tuberculosis Foundation, serving 14 TB LON regions 1 & 2 states in Nigeria, engaged SeamHealth to address the challenges of increasing demand for TB services, retention of patients in care, and tracking lost-to-follow-up patients. The project aimed to improve access to TB services, increase patient retention and enhance tracking of lost-to-follow-up patients through the implementation of Unstructured Supplementary Service Data (USSD) and Short Message Service (SMS) technologies. 

SeamHealth's role involved setting up and implementing the technology infrastructure, training healthcare staff on its use, and providing ongoing support to ensure successful adoption and usage.`,
        problem: 'Increase in demand for TB services, retention of TB patients in care, and tracking lost-to-follow-up TB patients',
        industry: 'Service delivery (accessibility), Information systems (data quality, analysis, dissemination, and use)',
        client: SmileTrainLogo,
        url: '/',
      },
      features : [
        {
          title : 'Dashboard',
          image : Kncv01
        },
        {
          title : 'Users',
          image : Kncv02
        },
      ],
      impact : {
        desc : `There was an increase in demand for TB services, improved retention of patients in care, and enhanced tracking of those who were lost to follow-up. This led to better management of TB patients, which ultimately resulted in improved health outcomes for individuals affected by the disease.`,
        images : [KncvImg01, KncvImg02]
      }
    }
  ]

  return (
    <>
        {/* Set Page title */}
        {/* <PageTitle title={"" +pages[params.service_id].heroTitle+" | SeamHealth Website "} />  */}
        <PageTitle title="Projects | SeamHealth Website " /> 
        <div id='address-section' className="project-section pt-52 flex justify-between flex-col overflow-hidden">
          <div className="w-2/4 gap-8 items-center p-8">
              <div className='text-white flex flex-col gap-6'>
                
                <img src={pages[params.project_id].logo} alt="" style={{width : 150}} />
                <h4 className="heading-four">{pages[params.project_id].title}</h4>
              </div>
          </div>

          <div className="grid grid-cols-1 gap-8 items-end">
              <img src={pages[params.project_id].heroImage} className='w-full' alt="" />
          </div>
        </div>

        {/* summary */}
        <section className='py-32 grid grid-cols-1 gap-x-6 gap-y-6 p-8'>
          <h4 className="heading-four text-gray500">Projects Overview</h4>
          <p className="text-gray100">
            {pages[params.project_id].summary.desc}
          </p>

          {/* <p className="text-gray100">
          At SeamHealth, we believe that patient satisfaction is crucial to the success of any hospital, which is why we are building PS to help hospitals of all sizes easily gather and analyze patient feedback.
          </p> */}

          <div className="grid grid-cols-1 md:grid-cols-2 mt-12 gap-12">
              <div>
                <h6 className="heading-six">
                    Problem Solved
                </h6>
                <p className="text-gray100">
                {pages[params.project_id].summary.problem}
                </p>
              </div>

              <div>
                <h6 className="heading-six">
                  Healthcare Pillar Addressed
                </h6>
                <p className="text-gray100">
                {pages[params.project_id].summary.industry}
                </p>
              </div>

              <div>
                <h6 className="heading-six">
                  Client
                </h6>
                <p className="text-gray100">
                  <img src={pages[params.project_id].summary.client} alt="" />
                </p>

                <div>
                <Link className='btn btn-primary w-max mt-8' to={pages[params.project_id].summary.url}>Visit website <img src={ArrowUpRight} alt="" /></Link>
              </div>
              </div>

              
          </div>
        </section>


                {/* solution */}
                <section className='solution-section py-28 p-8 '>
              <div className="s-inner flex flex-col justify-center items-center rounded-2xl">
                {/* <div> */}
                  
                  <img src={LightBulbIcon} alt="" className='mb-8' />
                    <h4 className="heading-four mb-4 text-center">
                        Some of Our Key Offerings
                    </h4>
                {/* </div> */}

                <div className='flex gap-x-8 mt-8'>
                  <Chip title={"USSD and SMS technologies for patient engagement"} icon={HealthcareIcon} />
                  <Chip title={"USSD and SMS technologies for patient engagement"} icon={HealthcareIcon} />
                  <Chip title={"USSD and SMS technologies for patient engagement"} icon={HealthcareIcon} />
                  
                </div>

                <div className='flex gap-x-8 mt-4 '>
                <Chip title={"USSD and SMS technologies for patient engagement"} icon={HealthcareIcon} />
                <Chip title={"USSD and SMS technologies for patient engagement"} icon={HealthcareIcon} />
                <Chip title={"USSD and SMS technologies for patient engagement"} icon={HealthcareIcon} />

                </div>
                <div className="crescent"></div>
              </div>
        </section>


        {/* Services list */}
        <section className='py-28 service-list grid grid-cols-1 gap-x-6 gap-y-6 p-8'>
            <h4 className="heading-four text-center">Features</h4>
            <div className="grid grid-cols-2 gap-6">
            <ProjectCard 
              image={pages[params.project_id].features[0].image} 
              title={pages[params.project_id].features[0].title}
              tag={false}
              />

<ProjectCard 
              image={pages[params.project_id].features[1].image} 
              title={pages[params.project_id].features[1].title}
              tag={false}
              />



            </div>
        
        </section>

          {/* Values Section */}
          <section className="single-service-section pt-36" id="values-section" style={{height : '100%'}}>
            <div className='grid grid-cols-1 gap-8 p-8'>
                <div>
                    {/* <h4 className="heading-four">{pages[params.service_id].heroTitle}</h4> */}
                    <h4 className="heading-four">Impact</h4>
                </div>

                <div>
                    <p className=''>
                      {pages[params.project_id].impact.desc}
                    </p>
                </div>

                <Link to={pages[params.project_id].summary.url} className="btn btn-primary w-max">Visit website <img src={ArrowUpRight} alt="" /> </Link>
            </div>

            <div className='grid grid-cols-2'>
                <img src={pages[params.project_id].impact.images[0]} className='opacity-50' alt="" />
                <img src={pages[params.project_id].impact.images[1]} className='opacity-50' alt="" />
            </div>
            {/* <div className='big-image' style={{backgroundImage : 'url('+pages[params.service_id].heroImage+')',}}></div> */}
            {/* <img src={ConsultationImg} alt="" style={{width : '100vw'}}/> */}
        </section>


         {/* recent project */}

         <div className="recent-projects p-4 md:p-8 mt-24">
          <div className='flex flex-col md:flex-row justify-between items-start gap-4 md:gap-0 md:items-center'>
          
              <div className="flex gap-y-2 flex-col">
                <h4 className="heading-four">More Projects</h4>
              </div>
              <button className='btn btn-primary'>See all projects <img src={ArrowUpRight} alt="" /></button>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 my-24 gap-8'>
            <ProjectCard 
              image={smiletrainthumbnail} 
              logo={SmileTrainLogo} 
              desc={"Smile Train is the largest cleft-focused organization, empowering local medical professionals in 70+ countries with training, funding, and resources to provide free cleft surgery and essential care in their communities."}
              link={'/'}
              />

              <ProjectCard 
              image={ElsrtMockup} 
              logo={ElsrtLogo} 
              desc={"Electronic laboratory sample referral & tracking system (ELSRT) is a software-based solution with features that support a modern laboratory’s operations."}
              link={'/'}
              />
          </div>
        </div>
    </>
  )
}

export default Projectdetail2