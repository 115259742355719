import React, { useEffect } from 'react'
import {useParams, Link} from 'react-router-dom'
import PageTitle from '../components/PageTitle'
import ProjectCard from '../components/ProjectCard'
import LogoAlt from '../assets/svgs/logo/seamhealth+logo+alt.svg'
import ElsrtMockup from '../assets/images/elsrt+mockup.png'
import ElsrtLogo from '../assets/svgs/logo/elsrt+logo.svg'
import ClientshoImg01 from '../assets/images/projects/clientshot+mockups/clientshot+img01.png'
import ClientshoImg02 from '../assets/images/projects/clientshot+mockups/clientshot+img02.png'
import ClientshotMockup from '../assets/images/clientshot+mockup.png'
import ClientshotLogo from '../assets/svgs/logo/Clientshot_logo_white.svg'
import ClientshotLogo2 from '../assets/images/projects/clientshot+mockup2.png'
import Tag from '../components/Tag'
import ProjectStacks from '../assets/images/projects/project+stacks.webp'
import CircleIcon from '../assets/svgs/icons/circle+icon.svg'
import SmileTrainMockup from '../assets/images/projects/smile+train+mockup.png'
import SmileTrainLogo from '../assets/images/projects/smiletrain-logo.svg'
import BridgeLogo from '../assets/images/projects/bridge+logo.svg'
import BridgeMockup from '../assets/images/projects/bridge+mockup.png'
import EkitiMockup from '../assets/images/projects/ekiti+health+mockup.png'
import EkitiLogo from '../assets/images/projects/ekiti+logo.png'
import KncvMockup from '../assets/images/projects/kncv+mockup.png'
import KncvLogo from '../assets/images/projects/kncv+logo.svg'
import ArrowUpRight from '../assets/svgs/icons/arrow-up-right.svg'
import clientshot01 from '../assets/images/projects/clientshot+mockups/clientshot001.png'
import clientshot02 from '../assets/images/projects/clientshot+mockups/clientshot002.png'
import clientshot03 from '../assets/images/projects/clientshot+mockups/clientshot003.png'
import clientshot04 from '../assets/images/projects/clientshot+mockups/clientshot004.png'
import clientshot05 from '../assets/images/projects/clientshot+mockups/clientshot005.png'
import clientshot06 from '../assets/images/projects/clientshot+mockups/clientshot006.png'
import smiletrainthumbnail from '../assets/images/projects/smiletrain+mockup/smiletrain+thumbnail.png'
import smiletrainHero from '../assets/images/projects/smiletrain+mockup/smiletrain+hero.png'
import ElsrtHero from '../assets/images/projects/elsrt+mockup/elsrt+hero.png'
import ihvnLogo from '../assets/images/clients/ihvn.svg'
import Elsrt01 from '../assets/images/projects/elsrt+mockup/elsrt01.png'
import Elsrt02 from '../assets/images/projects/elsrt+mockup/elsrt02.png'
import Elsrt03 from '../assets/images/projects/elsrt+mockup/elsrt03.png'
import Elsrt04 from '../assets/images/projects/elsrt+mockup/elsrt04.png'
import Elsrt05 from '../assets/images/projects/elsrt+mockup/elsrt05.png'
import Elsrt06 from '../assets/images/projects/elsrt+mockup/elsrt06.png'


import smile01 from '../assets/images/projects/smiletrain+mockup/smile01.png'
import smile02 from '../assets/images/projects/smiletrain+mockup/smile02.png'
import smile03 from '../assets/images/projects/smiletrain+mockup/smile03.png'
import smile04 from '../assets/images/projects/smiletrain+mockup/smile04.png'
import smile05 from '../assets/images/projects/smiletrain+mockup/smile05.png'
import smile06 from '../assets/images/projects/smiletrain+mockup/smile06.png'

import ElsrtImg01 from '../assets/images/projects/elsrt+mockup/eslrt+img01.png'
import ElsrtImg02 from '../assets/images/projects/elsrt+mockup/eslrt+img02.png'

import SmileImg01 from '../assets/images/projects/smiletrain+mockup/smile+img01.png'
import SmileImg02 from '../assets/images/projects/smiletrain+mockup/smile+img02.png'

const Projectdetail = () => {
  const params = useParams();

  const pages = [
    {id : 0}, //Don't delete....
    {
      id : 1,
      title : 'Patient Satisfaction',
      logo : ClientshotLogo,
      mockup : ClientshotMockup,
      heroImage : ClientshotLogo2,
      summary : {
        desc: `Patient Satisfaction (PS) is a new in-house project from SeamHealth aimed at improving patient experiences in hospitals. The project offers an easy-to-use solution for hospitals to gather and analyze patient feedback about their experiences. Hospitals can collect feedback through various channels including USSD, the web, and WhatsApp. The project also includes built-in analytics to track key metrics such as Net Promoter Score and overall patient satisfaction over time, allowing hospitals to make informed decisions about enhancing the patient experience. 
At SeamHealth, we believe that patient satisfaction is crucial to the success of any hospital, which is why we are building PS to help hospitals of all sizes easily gather and analyze patient feedback.`,
        problem: 'Low patient satisfaction in hospitals',
        industry: 'Service delivery (accessibility)',
        client: LogoAlt,
        url : 'https://clientshot.com'
      },
      features : [
        {
          title : 'User-friendly dashboard for insights and action plans',
          image : clientshot01
        },
        {
          title : 'Multi-channel feedback collection (USSD, web, WhatsApp)',
          image : clientshot02
        },
        {
          title : 'Customizable survey templates',
          image : clientshot03
        },
        {
          title : 'Secure data storage and management',
          image : clientshot04
        },
        {
          title : 'Real-time data analytics and reporting',
          image : clientshot05
        },
        {
          title : 'Net Promoter Score tracking',
          image : clientshot06
        },
      ],
      impact : {
        desc : 'Patient Satisfaction (PS) has the potential to improve patient experiences in hospitals by allowing them to gather and analyze feedback from patients about their experiences. By using the insights gained from this feedback, hospitals can make necessary improvements to increase patient satisfaction, improve patient outcomes, and drive growth.',
        images : [ClientshoImg01, ClientshoImg02]
      }
    },
    {
      id : 2,
      title : 'Electronic laboratory sample referral & tracking system',
      logo : ElsrtLogo,
      mockup : ElsrtMockup,
      heroImage : ElsrtHero,
      summary : {
        desc: `SeamHealth Ltd. was commissioned to develop and deploy the Cleft and Surgery E-Registry platform. This system is a web and mobile application that serves as an independent central database for all cleft repair and other surgeries performed in Nigeria. It gathers data from all six geo-political zones and the federal capital territory (FCT) within the country and is operated by Smile Train Inc. and the Federal Ministry of Health. 

The Cleft and Surgery E-Registry contains comprehensive patient information, including family demographics, child, maternal, and paternal data, as well as medical details such as birth dates and places of delivery. The platform tracks information on patients with birth defects, their cases, follow-up actions, and outcomes, ensuring a seamless and comprehensive registry.`,
        problem: 'Lack of a comprehensive and centralized system for tracking and managing information on patients with birth defects in Nigeria',
        industry: 'Service delivery (accessibility), Information systems (data quality, analysis, dissemination, and use)',
        client: ihvnLogo,
        url : 'https://dev.elsrt.com',
      },
      features : [
        {
          title : 'Mobile and web interfaces for easy access',
          image : Elsrt01
        },
        {
          title : 'Real-time location tracking of sample shipments',
          image : Elsrt02
        },
        {
          title : 'Detailed shipment reports (time, date, temperature)',
          image : Elsrt03
        },
        {
          title : 'Secure and encrypted data transmission',
          image : Elsrt04
        },
        {
          title : 'Detailed audit trail',
          image : Elsrt05
        },
        {
          title : 'Alerts and notifications for shipment status updates',
          image : Elsrt06
        },
      ],
      impact : {
        desc : `ELSRT made a significant impact in the laboratory services sector by addressing the long-standing challenge of inefficiencies in sample tracking and management. 
By improving the accuracy and efficiency of sample shipment from health facilities to testing laboratories, ELSRT ensured the quality of laboratory results.`,
        images : [ElsrtImg01, ElsrtImg02]
      }
    },
    {
      id : 3,
      title : 'Cleft and Surgery E-Registry Platform',
      logo : SmileTrainLogo,
      mockup : SmileTrainMockup,
      heroImage : smiletrainHero,
      summary : {
        desc: `SeamHealth Ltd. was commissioned to develop and deploy the Cleft and Surgery E-Registry platform. This system is a web and mobile application that serves as an independent central database for all cleft repair and other surgeries performed in Nigeria. It gathers data from all six geo-political zones and the federal capital territory (FCT) within the country and is operated by Smile Train Inc. and the Federal Ministry of Health. 

The Cleft and Surgery E-Registry contains comprehensive patient information, including family demographics, child, maternal, and paternal data, as well as medical details such as birth dates and places of delivery. The platform tracks information on patients with birth defects, their cases, follow-up actions, and outcomes, ensuring a seamless and comprehensive registry.`,
        problem: 'Lack of a comprehensive and centralized system for tracking and managing information on patients with birth defects in Nigeria',
        industry: 'Service delivery (accessibility), Information systems (data quality, analysis, dissemination, and use)',
        client: SmileTrainLogo,
        url: '/',
      },
      features : [
        {
          title : 'Web and mobile interfaces for data entry and access',
          image : smile01
        },
        {
          title : 'Comprehensive patient and family demographics tracking',
          image : smile02
        },
        {
          title : 'Insightful Activity Tracker',
          image : smile03
        },
        {
          title : 'Centralized database for patient information',
          image : smile04
        },
        {
          title : 'Real-time analytics and reporting',
          image : smile05
        },
        {
          title : 'Secure and encrypted data storage',
          image : smile06
        },
      ],
      impact : {
        desc : `The Cleft and Surgery E-Registry platform had the potential to significantly improve the management and tracking of information on patients with birth defects in Nigeria. By providing a centralized and comprehensive system for tracking and managing patient information, the platform helped to ensure timely and effective care for patients with cleft and other birth defects.`,
        images : [SmileImg01, SmileImg02]
      }
    }
  ]

  return (
    <>
        {/* Set Page title */}
        {/* <PageTitle title={"" +pages[params.service_id].heroTitle+" | SeamHealth Website "} />  */}
        <PageTitle title="Projects | SeamHealth Website " /> 
        <div id='address-section' className="project-section pt-52 flex justify-between flex-col overflow-hidden">
          <div className="w-2/4 gap-8 items-center p-8">
              <div className='text-white flex flex-col gap-6'>
                
                <img src={pages[params.project_id].logo} alt="" style={{width : 150}} />
                <h4 className="heading-four">{pages[params.project_id].title}</h4>
              </div>
          </div>

          <div className="grid grid-cols-1 gap-8 items-end">
              <img src={pages[params.project_id].heroImage} className='w-full' alt="" />
          </div>
        </div>

        {/* summary */}
        <section className='py-32 grid grid-cols-1 gap-x-6 gap-y-6 p-8'>
          <h4 className="heading-four text-gray500">Projects Overview</h4>
          <p className="text-gray100">
            {pages[params.project_id].summary.desc}
          </p>

          {/* <p className="text-gray100">
          At SeamHealth, we believe that patient satisfaction is crucial to the success of any hospital, which is why we are building PS to help hospitals of all sizes easily gather and analyze patient feedback.
          </p> */}

          <div className="grid grid-cols-1 md:grid-cols-2 mt-12 gap-12">
              <div>
                <h6 className="heading-six">
                    Problem Solved
                </h6>
                <p className="text-gray100">
                {pages[params.project_id].summary.problem}
                </p>
              </div>

              <div>
                <h6 className="heading-six">
                  Healthcare Pillar Addressed
                </h6>
                <p className="text-gray100">
                {pages[params.project_id].summary.industry}
                </p>
              </div>

              <div>
                <h6 className="heading-six">
                  Client
                </h6>
                <p className="text-gray100">
                  <img src={pages[params.project_id].summary.client} alt="" />
                </p>

                <div>
                <Link className='btn btn-primary w-max mt-8' to={pages[params.project_id].summary.url}>Visit website <img src={ArrowUpRight} alt="" /></Link>
              </div>
              </div>

              
          </div>
        </section>

        {/* Services list */}
        <section className='py-28 service-list grid grid-cols-1 gap-x-6 gap-y-6 p-8'>
            <h4 className="heading-four text-center">Features</h4>
            <div className="grid grid-cols-2 gap-6">
            <ProjectCard 
              image={pages[params.project_id].features[0].image} 
              title={pages[params.project_id].features[0].title}
              tag={false}
              />

<ProjectCard 
              image={pages[params.project_id].features[1].image} 
              title={pages[params.project_id].features[1].title}
              tag={false}
              />

<ProjectCard 
              image={pages[params.project_id].features[2].image} 
              title={pages[params.project_id].features[2].title}
              tag={false}
              />

<ProjectCard 
              image={pages[params.project_id].features[3].image} 
              title={pages[params.project_id].features[3].title}
              tag={false}
              />

<ProjectCard 
              image={pages[params.project_id].features[4].image} 
              title={pages[params.project_id].features[4].title}
              tag={false}
              />

<ProjectCard 
              image={pages[params.project_id].features[5].image} 
              title={pages[params.project_id].features[5].title}
              tag={false}
              />

            </div>
        
        </section>

          {/* Values Section */}
          <section className="single-service-section pt-36" id="values-section" style={{height : '100%'}}>
            <div className='grid grid-cols-1 gap-8 p-8'>
                <div>
                    {/* <h4 className="heading-four">{pages[params.service_id].heroTitle}</h4> */}
                    <h4 className="heading-four">Impact</h4>
                </div>

                <div>
                    <p className=''>
                      {pages[params.project_id].impact.desc}
                    </p>
                </div>

                <Link to={pages[params.project_id].summary.url} className="btn btn-primary w-max">Visit website <img src={ArrowUpRight} alt="" /> </Link>
            </div>

            <div className='grid grid-cols-2'>
                <img src={pages[params.project_id].impact.images[0]} className='opacity-50' alt="" />
                <img src={pages[params.project_id].impact.images[1]} className='opacity-50' alt="" />
            </div>
            {/* <div className='big-image' style={{backgroundImage : 'url('+pages[params.service_id].heroImage+')',}}></div> */}
            {/* <img src={ConsultationImg} alt="" style={{width : '100vw'}}/> */}
        </section>


         {/* recent project */}

         <div className="recent-projects p-4 md:p-8 mt-24">
          <div className='flex flex-col md:flex-row justify-between items-start gap-4 md:gap-0 md:items-center'>
          
              <div className="flex gap-y-2 flex-col">
                <h4 className="heading-four">More Projects</h4>
              </div>
              <button className='btn btn-primary'>See all projects <img src={ArrowUpRight} alt="" /></button>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 my-24 gap-8'>
            <ProjectCard 
              image={smiletrainthumbnail} 
              logo={SmileTrainLogo} 
              desc={"Smile Train is the largest cleft-focused organization, empowering local medical professionals in 70+ countries with training, funding, and resources to provide free cleft surgery and essential care in their communities."}
              link={'/'}
              />

              <ProjectCard 
              image={ElsrtMockup} 
              logo={ElsrtLogo} 
              desc={"Electronic laboratory sample referral & tracking system (ELSRT) is a software-based solution with features that support a modern laboratory’s operations."}
              link={'/'}
              />
          </div>
        </div>
    </>
  )
}

export default Projectdetail